import type { AppProps } from "next/app";
import { ThemeProvider } from "@material-ui/core/styles";
import { appWithTranslation } from "next-i18next";

import { theme } from "@/config/theme";

import "@/styles/globals.scss";

const App = ({ Component, pageProps }: AppProps) => (
  <ThemeProvider theme={theme}>
    <Component {...pageProps} />
  </ThemeProvider>
);

export default appWithTranslation(App);
