import { createTheme } from "@material-ui/core/styles";

export const red = "#c0311a";

export const blue = "#2283c5";

export const theme = createTheme({
  palette: {
    primary: {
      main: blue,
    },
    secondary: {
      main: red,
    },
  },
  typography: {
    fontSize: 14,
    h1: { fontSize: "2.5rem", fontWeight: 400, color: "#2283c5" },
    h2: { fontSize: "2rem", fontWeight: 400 },
    h3: { fontSize: "1.8rem" },
    h4: { fontSize: "1.6rem" },
    h5: { fontSize: "1.2rem", fontWeight: 600 },
    h6: { fontSize: "1rem", textTransform: "uppercase" },
  },
  shape: {
    borderRadius: 0,
  },
});
